/* *,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

ul li {
  list-style: none;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1.2;
  font-size: 16px;
  font-family: Open Sans, sans-serif;
}

a {
  text-decoration: none;
}
input,
button,
textarea,
select {
  font: inherit;
}

img,
picture {
  max-width: 100%;
  display: block;
}

#root,
#__next {
  isolation: isolate;
} */

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}
html,
body {
  height: 100%;
  width: 100%;
  line-height: 1.2;
  font-size: 16px;
  font-family: Open Sans, sans-serif;
}

h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  font-weight: bold;
  text-align: center;
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  font-weight: bold;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: bold;
}
h4 {
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  font-weight: bold;
}
h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  font-weight: bold;
}
h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  font-weight: bold;
}
a {
  text-decoration: none;
}
ul li {
  list-style: none;
}
input,
button,
textarea,
select {
  font: inherit;
}
img,
picture {
  max-width: 100%;
  display: block;
}
