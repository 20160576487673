.container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 15px;
}
.color {
  color: white;
}
.title {
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  color: white;
}
.flex_container {
  display: flex;
  flex-wrap: wrap;
}
.flex_left {
  padding: 10px;
  flex: 50%;
}
.flex_right {
  padding: 10px;
  flex: 50%;
}
.heading::after {
  content: '';
  display: block;
  height: 8px;
  width: 100%;
  background: url('/public/images/devider.png') no-repeat center center;
  margin-top: 22px;
}
@media (max-width: 767px) {
  .flex_right,
  .flex_left {
    flex: 100%;
  }
}

/* header */
.header_container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 15px;
  text-align: justify;
}
.header {
  padding: 10px 0 10px;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.95;
  font-size: 18px;
  position: sticky;
  top: 0;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.navbar_title {
  font-size: 1.5rem;
  margin: 0.5rem;
}
.navbar_links ul {
  display: flex;
}
.navbar_links ul li {
  color: #fff;
  padding: 1rem;
}

.navbar_links li a {
  color: #fff;
}
.navbar_links a.active {
  color: #5785f1;
  font-weight: bold;
}
.navbar_links a:not(.active):hover {
  color: #84a6f5;
}
.toggle_button {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 100%;
}
.header_button {
  background-color: black;
  border-radius: 7px;
  color: white;
  padding: 10px 15px;
  cursor: pointer;
}

/* footer */
.footer_title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 35px;
  text-align: center;
  color: #2763ee;
  padding-bottom: 20px;
}

@media (min-width: 767px) {
  .navbar_links ul li:last-child {
    margin-left: 20px;
  }
}
@media (max-width: 768px) {
  .toggle_button {
    display: flex;
  }
  .navbar_links {
    display: none;
    width: 100%;
  }
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar_links.active ul {
    flex-direction: column;
    width: 100%;
  }
  .navbar_links.active li {
    text-align: center;
  }
  .navbar_links.active {
    display: flex;
  }
  .navbar_links ul li {
    padding: 0.5rem;
  }
}

/* about */
.item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.icon {
  margin-right: 20px;
}
.faIcon {
  font-size: 30px;
  color: blue;
}
.description {
  color: #67696c;
}

/* darbai */
.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  margin: auto;
}
.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  cursor: pointer;
}
.arrow:hover {
  cursor: pointer;
}
.arrow_left {
  left: 1rem;
}
.arrow_right {
  right: 1rem;
}
.slide_hidden {
  display: none;
}
.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}
.indicator {
  background-color: white;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}
.indicator_inactive {
  background-color: grey;
}

/* Duk */
.collapsible_panel {
  margin: 1rem 0;
  padding: 1rem;
  background-color: #1274ca;
  border-radius: 0.5rem;
  display: grid;
  grid-template-rows: auto 0fr;
  transition: grid-template-rows 500ms ease-out;
  cursor: pointer;
}
.collapsible_panel.open {
  grid-template-rows: auto 1fr;
}
.panel_header {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: white;
  font-size: 16px;
}
.panel_header .toggle_button {
  margin-right: 1rem;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
.panel_content {
  overflow: hidden;
  color: white;
  margin-top: 10px;
}
.panel_button {
  margin-left: 10px;
  margin-right: 1rem;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

/* contact */
.contact {
  background-color: black;
  background-repeat: no-repeat;
  background-image: url('/public/images/kontaktai.png');
  background-size: cover;
  background-attachment: fixed;
  height: 420px;
}
.flex_container_contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.forms_group {
  margin-bottom: 15px;
}
.forms_control {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: transparent;
  color: white;
}
.btn {
  background: #7197ef;
  border: 1px solid #ccc;
  padding: 10px 20px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: color 400ms, background-color 400ms, border-color 400ms;
}
.btn:hover {
  background-color: #3062d9;
}
.contact_row {
  display: flex;
  margin-right: 10px;
  line-height: 32px;
}
.contact_text {
  margin-bottom: 10px;
}
.contact_row .icon,
.contact_row .text {
  display: flex;
  align-items: center;
}
.contact_row .icon {
  margin-right: 10px;
}
.contact_row .icon svg {
  font-size: 22px;
}
.contact_row .text img {
  width: 100%;
}
@media (max-width: 480px) {
  .contact {
    height: 630px;
  }
}
@media (max-width: 767px) {
  form {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .order1 {
    order: 1;
  }
  .order2 {
    order: 2;
  }
  .order3 {
    order: 3;
  }
  .order4 {
    order: 4;
  }
  .order5 {
    order: 5;
  }
  .order6 {
    order: 6;
  }
  .order7 {
    order: 7;
  }
  .order8 {
    order: 8;
  }
}

/* home */
.home {
  background-color: black;
  background-repeat: no-repeat;
  background-image: url('/public/images/s11.jpg');
  background-size: cover;
  background-position: right bottom;
  opacity: 1;
  visibility: inherit;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* privalumai */
.step_number {
  min-width: 68px;
  height: 68px;
  background: #3c70ed;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  padding-left: 20px;
}
.step_number span {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.step {
  display: flex;
  padding-top: 50px;
}
.step_number_margin {
  margin-left: 20px;
}

/* moduliai */
.flex_container_module {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.img_module {
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media (min-width: 320px) {
  .home {
    height: 200px;
  }
  .title {
    font-size: 20px;
  }
  h1 {
    font-size: 1.3em;
  }
  h2 {
    font-size: 1em;
  }
}
@media (min-width: 481px) {
  .home {
    height: 300px;
  }
}
@media (min-width: 768px) {
  .home {
    height: 400px;
  }
  .title {
    font-size: 28px;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
}
@media (min-width: 1025px) {
  .home {
    height: 400px;
  }
}
